import React from 'react'
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Paginator from "../../shared/Paginator";

const Huldra = styled.div`
    position: relative;
    padding-top: 1rem;
    text-align: center;

    .narrow {
        max-width: 740px;
        margin: 0 auto;
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                huldraImg: file(relativePath: { eq: "NordicInfluences/nordic-Huldra-580.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 580, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP])
                    }
                }
                northerLight: file(relativePath: { eq: "NordicInfluences/nordic-northernlights-580.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 590, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Huldra>
                <Container fluid={true} className="no-padding-xs">
                        <Row className="mb-4 mb-xl-5">
                            <Col xs={12} xl={6} className="my-auto order-2 order-xl-1 pt-4 pt-xl-0">
                                <div className="narrow">
                                    <p className="font--regular mb-4">Key to the story is the far northern latitude, which causes nearly endless sunshine in the summer and almost total darkness in the winter, during which the famed Northern Lights dance in the sky. The balance of summer and winter is essential to survival; without summer there will be nothing to harvest to sustain life during the harsh, cold, dark winter. Among the animals that can survive the extreme temperatures are reindeer, which have played an important role in Nordic communities for centuries providing labour, food, and clothing.</p>
                                    <p className="font--regular mb-4">The extremes of day and night experienced by Nordic people for millennia have naturally inspired rich outdoor and indoor cultural traditions. One of the latter is encapsulated by the Danish word hygge (also used in Norway), which is loosely translated as “cosy” and applies to any indoor activities that bring warmth and happiness, often shared with family and friends. Song and dance also form an important part of Nordic folk traditions. The songs of the S&aacute;mi people, who today live across the far northern regions of Norway, Sweden, Finland and even Russia – well above the Arctic Circle – are rooted in a unique vocal style called joiking, which is featured in FROZEN.</p>
                                    <Paginator />
                                </div>

                            </Col>
                            <Col xs={12} xl={6} className="my-auto order-1 order-xl-2">
                                <div className="narrow">
                                 <GatsbyImage placeholder="none" image={getImage(data.northerLight)} alt="" title=""
                                     className="img-fluid d-block"/>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-4 mb-xl-5">
                            <Col xs={12} xl={6} className="my-auto">
                                <div className="narrow">
                                <GatsbyImage placeholder="none" image={getImage(data.huldraImg)} alt="" title=""
                                     className="img-fluid"/>
                                </div>
                            </Col>
                            <Col xs={12} xl={6} className="my-auto pt-4 pt-xl-0">
                                <div className="narrow">
                                    <p className="font--regular mb-4">The vast night of the Nordic winter has long ignited the imagination of its people and inspired diverse mythologies. Norway’s iconic trolls, which were featured in the animated FROZEN film, have been transformed in the stage adaptation into “Hidden Folk,” more human-like fantastical creatures inspired by the elfin huldufólk mythology from Iceland and the Faroe Islands.</p>

                                    <p className="font--regular mb-4">As with any fairy tale, research into the real geographies, mythologies, and cultural traditions of our world ultimately gives way to the imaginations of its creators. Although inspired by the rich and beautiful Nordic countries, Arendelle and FROZEN are pure fiction.</p>
                                    <Paginator />
                                </div>
                            </Col>
                        </Row>
                </Container>
            </Huldra>
        )}
    />
)

export default Query;
