import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import PropTypes from "prop-types";
import {Link} from "gatsby"

const CardWrapper = styled.div`
    background: linear-gradient(180deg, #7BF6EF 0%, #FFFFFF 50%);
    width: 100%;
    text-align: center;
    padding: 1rem;
    
    &:nth-child(1) {
        margin-bottom: 2rem;
        
        @media ${media.xl} {
            margin-bottom: 0;
        }
    }
    
    @media ${media.sm} {
        padding: 2rem;
    }
    
    @media ${media.xl} {
        width: 48%;
    }
    
    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        font-size: 24px;
        margin-bottom: 1rem;
        line-height: 100%;
        
        @media ${media.sm} {
            font-size: 30px;
        }
        
    }
    
    p {
        max-width: 450px;
        min-height: 50px;
    }   
`

class Card extends Component {
    render() {
        return (
            <CardWrapper className="narrow mx-auto">
                    <h3>{this.props.title}</h3>
                <p className="smaller text-center mx-auto mt-2">{this.props.copy}</p>
                <Link to={`/beyond-the-story/${this.props.btnLink}/`}
                      className="btn btn--new d-flex mx-auto btn--color-white text-uppercase"><span>Explore</span></Link>
            </CardWrapper>
        );
    }
}

export default Card;

Card.defaultProps = {
    gradient: false
}

Card.propTypes = {
    title: PropTypes.string,
    copy: PropTypes.string,
    btnLink: PropTypes.string,
    gradient: PropTypes.bool,
}