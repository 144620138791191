import React, {Component} from "react"
import {StaticQuery, graphql} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import HeadingSpecial from "../../shared/HeadingSpecial"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"

const Relative = styled.div`
    position: relative;
    height: 100%;
    
    &.flex {
        @media ${media.sm} {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }   
`

const VideoContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 3;  
    
     @media ${media.md} {
        top: 0; 
     }
    
    .bottomCopy {
        position: absolute;
        bottom: 50px;
        max-width: 740px;
        text-align: center;
        color: ${props => props.theme.colors.white};
        left: 50%;
        transform: translateX(-50%);
        padding: 0 1rem;
        width: 100%;
        
        @media ${media.sm} {
            bottom: 60px;
        }
        
        @media ${media.md} {
            bottom: 80px;
        }
    }
`

const VideoContent = styled.div`
    width: 100%;
    max-width: 740px;
    text-align: center;
    
    @media ${media.sm} {
        color: ${props => props.theme.colors.greyDark};
    }
    
    @media ${media.md} {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    @media ${media.xl} {
        top: 0;
    }
    
    .btn {
        margin: 2rem auto 0;
    }
`

export default class NorvegianBg extends Component {
    renderItems(data) {
        return (
            <Relative className="mb-4">
                <GatsbyImage placeholder="none" image={getImage(data.desktopImage)} alt="" className="img-fluid d-none d-lg-block"/>
                <GatsbyImage placeholder="none" image={getImage(data.tabletImage)} alt="" className="img-fluid d-none d-sm-block d-lg-none"/>
                <GatsbyImage placeholder="none" image={getImage(data.mobileImage)} alt="" className="img-fluid d-sm-none"/>
            </Relative>
        );
    }

    render() {
        return (
            <StaticQuery query={query} render={(props)=>this.renderItems(props)} />
        )
    }
}


const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "NordicInfluences/header-nordic-576.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 575, quality: 100)
      }
    }
    tabletImage: file(relativePath: { eq: "NordicInfluences/header-nordic-991.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 991, quality: 100)
      }
    }
    desktopImage: file(relativePath: { eq: "NordicInfluences/header-nordic-1920.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }
  }
`