import React, {Component} from 'react'
import HeadingSpecial from "../shared/HeadingSpecial";
import {Container} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import Card from "../shared/Card";
import PropTypes from "prop-types";
import BgXs from "../../images/HomePage/bg-grad-576.svg";
import BgMd from "../../images/HomePage/bg-grad-991.svg";
import BgXl from "../../images/HomePage/bg-grad-1920.svg";

const Bg = styled.div`
    background: url("${BgXs}") no-repeat center center;
    background-size: cover;
    padding: 2rem 0;
    
    @media ${media.sm} {
        background: url("${BgMd}") no-repeat center center;
        background-size: cover;
        padding: 3rem 2rem;
    }
    
    @media ${media.xl} {
        background: url("${BgXl}") no-repeat center center;
        background-size: cover;
    }
`
const CardWrapper = styled.div`
    @media ${media.xl} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: stretch;
    }
`

class MoreTopics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        }
    }

    toggleModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        return (
            <Bg>
                <Container fluid={true}>
                    <Container>
                        <HeadingSpecial lines={false} headingTag="h2" className="mb-5" title="More Topics"/>
                        <CardWrapper>
                            {this.props.inspiration &&
                            <Card
                                title="Inspiration"
                                copy="Explore the influences of FROZEN from the original source material, to the animated feature film, to the Nordic traditions from which the creative team drew inspiration."
                                btnLink="inspiration/nordic-influences"
                                slug="inspiration"
                            />
                            }
                            {this.props.adaptation &&
                            <Card
                                title="Adaptation"
                                copy="Dive into the story of FROZEN and how the story is brought to life on stage."
                                btnLink="adaptation/characters"
                                slug="adaptation"
                            />
                            }
                            {this.props.design &&
                            <Card
                                title="Design"
                                copy="Explore the stunning designs of FROZEN the Musical."
                                btnLink="design/costumes"
                                slug="design"
                            />
                            }
                        </CardWrapper>
                </Container>
            </Container>
            </Bg>
        );
    }
}

export default MoreTopics;

MoreTopics.propTypes = {
    inspiration: PropTypes.bool,
    adaptation: PropTypes.bool,
    design: PropTypes.bool,
}